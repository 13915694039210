import React from 'react';
import { Route } from 'react-router-dom';

import { IS_ADMIN } from '../graphql/queries/user/Profile';
import { useQuery } from '@apollo/client';

const AdminRoute = ({ ...rest }) => {
  const { data } = useQuery(IS_ADMIN);



  const renderContent = () => <Route {...rest} />;

  return data && data.isAdmin.level === 1 ? renderContent() : null;
};

export default AdminRoute;
