import React from 'react';
import { Link, useHistory } from 'react-router-dom';
const moment = require('moment');
moment.locale('cs');

const PostCard = ({
  post,
  showUpdateButton = false,
  showDeleteButton = false,
  handleDelete = (f) => f,
}) => {
  const history = useHistory();

  console.log(post.images);

  return (
    /*  <div className="card text-center" style={{ minHeight: '375px' }}>
       <Link onClick={() => history.goBack()}>Zpět</Link>
      <div className="card-body">
        <Link to={`/projekt/${post._id}`}>
        </Link>
        <h4 className="text-primary">{post.projekt}</h4>
        <hr />
        <ul className="list-unstyled">
          <li className="font-weight-bold">{post.postedBy.prijmeni}</li>
        </ul>
        <p>{post.misto}</p>
         <br></br>
         {JSON.stringify(images)}
        <Link to={`/projekt/${post._id}`}>Zobrazit projekt</Link>
        <br /> <br />
     
        {showDeleteButton && (
          <button
            onClick={() => handleDelete(post._id)}
            className="btn m-2 btn-danger"
          >
            Vymazat
          </button>
        )}
        {showUpdateButton && (
          <button
            onClick={() => history.push(`/upravit/projekty/${post._id}`)}
            className="btn m-2 btn-warning"
          >
            Aktualizovat
          </button>
        )}
      </div>
    </div>*/ <div className="row">
      <div className="col-lg-6">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">Projekt</h6>
          </div>
          <div className="card-body">
            <dl className="row pt-3">
              <dt className="col-sm-5">Název projektu </dt>
              <dd className="col-sm-7">{post.projekt}</dd>
              <dt className="col-sm-5">Stupeň projektové dokumentace</dt>
              <dd className="col-sm-7">{post.stupen}</dd>
              <dt className="col-sm-5">Druh stavby</dt>
              <dd className="col-sm-7">{post.druh}</dd>
              <dt className="col-sm-5">Místo stavby</dt>
              <dd className="col-sm-7">{post.misto}</dd>
              <dt className="col-sm-5">Investor</dt>
              <dd className="col-sm-7">{post.investor}</dd>
              <dt className="col-sm-5">Množství</dt>
              <dd className="col-sm-7">{post.mnozstvi}</dd>
              <dt className="col-sm-5">Předpokládaný termím realizace  </dt>
              <dd className="col-sm-7">
                {' '}
                {moment(post.date).format('DD.MM.YYYY')}
              </dd>
              <dt className="col-sm-5">Skupina zboží</dt>
              <dd className="col-sm-7">
                {' '}
                {post.skupina &&
                  post.skupina.map((g) => <dd>{g.value} </dd>)}{' '}
              </dd>
            </dl>
          </div>
        </div>
      </div>{' '}
      <div className="col-lg-4">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">Dokumenty </h6>
          </div>
          <div className="card-body">
            <div className="d-flex flex-wrap">
              {post.images &&
                post.images.map((image) => (
                  <div className="w-25 py-3 pr-3">
                    {' '}
                    <a href={image.url} target="_blank">
                      <img
                        src={image.url}
                        alt="https://via.placeholder.com/200x200.png?text=Dokument"
                        class=" w-100 h-100 d-inline-block"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            'https://via.placeholder.com/200x200.png?text=Dokument';
                        }}
                      />
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          {' '}
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">
              Stav projektu{' '}
            </h6>
          </div>{' '}
          <div className="card-body">
            <dl className="row pt-3">
              {' '}
              <dt className="col-sm-5">Počet bodu</dt>
              <dd className="col-sm-7">
                <span className="text-primary">
                  <strong>{post.credit}</strong>
                </span>
              </dd>
            </dl>{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
