import React from 'react';

const Heading = ({ children, title, row = 'row' }) => {
  return (
    <div className="d-sm-flex justify-content-between align-items-center mb-4">
      <h3 className="text-dark mb-0">{title}</h3>
    </div>
  );
};

export default Heading; /*



*/

/* <a
          className="btn btn-primary btn-sm d-none d-sm-inline-block"
          role="button"
          href="#"
        >
          <i className="fas fa-download fa-sm text-white-50"></i>&nbsp;Generate
          Report
        </a>
      </div>
      <div className={row}>{children}</div> */
