import React from 'react';
import { useQuery } from '@apollo/client';
import Heading from '../components/Heading';
import Box from '../components/Box';
import Credit from '../components/Credit';
import Catalogs from '../components/Catalogs';
import { TOTAL_POSTS_BY_USER } from '../graphql/queries/post/Post';
import Welcome from '../assets/img/welcome.jpg';
import Pce from '../assets/img/pce.png';
import Wattsonic from '../assets/img/wattsonic.png'
import { POSTS_BY_USER } from '../graphql/queries/post/Post';

const Dashboard = () => {
  const { data: projektu } = useQuery(TOTAL_POSTS_BY_USER);
  const { data: pocet } = useQuery(POSTS_BY_USER);

  return (
    <div className="container-fluid">
      <Heading title={'Nástěnka'} />
      <div className="row">
        <Box
          heading={'Získané body'}
          value={<Credit />}
          icon={'fas fa-calendar fa-2x text-gray-300'}
          color="primary"
        />
        <Box
          heading={'Počet projektů'}
          value={pocet && pocet.postsByUser.length}
          icon={'fas fa-comments fa-2x text-gray-300'}
          color={'success'}
        />
      </div>
      <div className="row">
        <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Představujeme nový asymetrický hybridní FTV systém společnosti
                Wattsonic
              </h6>
            </div>
            <div className="card-body">
              <div>
                <img
                  className="img-fluid mt-3 mb-4"
                  style={{ width: '25rem' }}
                  src={Wattsonic}
                  alt
                />
              </div>
              <p>
                Na český a slovenský fotovoltaický trh vstupuje již druhá
                generace asymetrických hybridních systémů Wattsonic, které se
                vyznačují především nadprůměrnými cykly baterií a jejich vysokou
                životností. Velmi jednoduchá instalace Plug in and Play umožňuje
                rychlé zapojení a oživení systému se všemi požadavky české
                distribuční el. sítě. Wattsonic vlastní přibližně 100 patentů a
                je jednou z mála společností, ve které probíhá kompletní výrobní
                proces.
              </p>
              <ul>
                <li>životnost baterií 10 000 cyklů DoD 90%, záruka 10let,</li>
                <li>provozní napětí 180 – 750V</li>
                <li>doba nabíjecího a vybíjecího cyklu 1 hodina (1C)</li>
                <li>různé možnosti instalace baterií (stěna/zem)</li>
                <li>ucelená produktová řada</li>
                <li>rychlá instalace plug and play</li>
                <li>vlastní spotřeba střídače jen 30W</li>
                <li>pasivní chlazení střídače - tichý chod</li>
                <li>
                  možnost dálkového nastavení přes aplikaci Wattsonic Smart App
                </li>
                <li>možnost nastavení nulového přetoku do sítě</li>
                <li>funkce UPS (do10ms)</li>
                <li>vyrovnávání proudových špiček (peak shaving)</li>
                <li>okamžitá reakce na změnu napětí (konkurence až 45s)</li>
                <li>otevřený protokol pro nadřazené řídící systémy EMS</li>
                <li>
                  možnost využití technické podpory na místě při první instalaci
                </li>
                <li>stálé skladové zásoby v ČR</li>
              </ul>
               <span><a href="https://schmachtl.us14.list-manage.com/track/click?u=ef494d176bd9802185653befc&id=442ab4b5ba&e=3820fc0f99" target="_blank"> Zobrazit katalog</a></span>
            
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Vítáme Vás</h6>
            </div>
            <div className="card-body">
              <div className="text-left">
                <img
                  className="img-fluid mt-3 mb-4"
                  style={{ width: '25rem' }}
                  src={Welcome}
                  alt
                />
              </div>
              <p>
                Vítáme Vás v registru elektroinstalačních projektů, který jsme
                vytvořili i jako knihovnu s aktuálními katalogy a materiálem,
                který může pomoci v sestavování vhodného řešení. Naleznete zde
                technické parametry, kompletní řady našich produktů a dostanete
                se rychle k novinkám firmy Schmachtl. Je to cesta, jak v této
                nelehké koronavirové době, předat informace z našeho sortimentu.
              </p>
              <p>
                {' '}
                Cílem je předevší ušetřit Váš čas při navrhování projektů. Při
                registraci sbíráte body, které lze směnit za hodnotné ceny z
                našeho seznamu. Více vám s tímto registrem pomohou naši obchodní
                zástupci a proto je neváhejte kontaktovat. Telefonický seznam
                naleznete níže. Budeme si vážit za jakékoliv připomínky a
                úpravy, které by ještě více zefektivnili Vaši práci.
              </p>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Průmyslové vidlice a zásuvky TopTaurus2
              </h6>
            </div>
            <div className="card-body">
              <div>
                <img
                  className="img-fluid  mt-3 mb-4"
                  style={{ width: '25rem' }}
                  src={Pce}
                  alt
                />
              </div>
              <p>
                Nyní přichází na trh výrazně inovovaná řada TOPTAURUS2, která
                přináší celou řadu vylepšení o opět výrazně zkracuje čas
                potřebný k instalaci a významně zvyšuje uživatelský komfort a
                bezpečnost. Všechny modely konektorů jsou nyní volitelně k
                dispozici s indikátorem napětí LED.
              </p>
              <p>
                {' '}
                Robustnost a jednoduchost instalace to vždy byly nejlepší
                vlastnosti naší řady bezpečnostních vidlic a spojek řady
                TopTaurus dodávané již od roku 2006.{' '}
                <a
                  href="https://www.blog.schmachtl.cz/prumyslove-vidlice-a-zasuvky-toptaurus2"
                  target="_blank"
                >
                  Více informací
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Katalogy </h6>
            </div>
            <Catalogs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
