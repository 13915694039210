import React from 'react';
import Heading from '../components/Heading';
import AdminProjectListTable from '../components/AdminProjectsListTable';

const AdminProjectsList = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Seznam všech projektů'} />
      <AdminProjectListTable />
    </div>
  );
};

export default AdminProjectsList;
