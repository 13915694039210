import Routes from './routes/routes';
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from '@apollo/client';

import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { AuthContext } from './context/authContext';

const App = () => {
  const { state } = useContext(AuthContext);
  const { user } = state;

  // 1. create websocket link
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
    options: {
      reconnect: true,
    },
  });

  // 2. create http link

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  });

  // 3. setContex for authtoken

  const authLink = setContext(() => {
    return {
      headers: { authtoken: user ? user.token : '' },
    };
  });

  // 4 concat http and authtoken link

  const httAuthLink = authLink.concat(httpLink);

  // 5 use split to split http link or webscoket link

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httAuthLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),

    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <ToastContainer />
      <Routes />
    </ApolloProvider>
  );
};

export default App;
