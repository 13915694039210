import React, { Fragment } from 'react';
import Heading from '../components/Heading';
import User from '../components/User';

const Profile = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Uživatel'} />

      <div className="row mb-3">
        <User />
      </div>
    </div>
  );
};

export default Profile;
