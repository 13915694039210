import React, { useState } from 'react';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';
import AuthForm from '../../components/forms/AuthForm';

function Registration() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
  
      const config = {
        url: process.env.REACT_APP_CONFIMATION_EMAIL_REDIRECT,
        handleCodeInApp: true,
      };
      
      const legal = await auth.fetchSignInMethodsForEmail(email)
      console.log(legal)
  
      if (legal.length > 0 ) {
  
        return toast.error('Email se už používá')
      }
  
      const result = await auth.sendSignInLinkToEmail(email, config);
  
      // Show toast notification to user about email
      toast.success(`Potvrzovací zpráva byla odeslána na ${email}`);
  
      // save user email to local storage
  
      window.localStorage.setItem('emailForRegistration', email);
  
      // cleat state
  
      setEmail('');
      setLoading(false);
    };
    return (
     
  
        
      <AuthForm email={email} loading={loading} setEmail={setEmail} handleSubmit={handleSubmit} akce='Registrovat se' />
     
    );
}

export default Registration
