import React, { useContext, Fragment } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { AuthContext } from '../../context/authContext';
import Image from '../Image';

const ImageUpload = ({
  setValues,
  setLoading,
  values,
  loading,
  singleUpload = false,
}) => {
  const { state } = useContext(AuthContext);

  const fileResizeAndUpload = (e) => {
    setLoading(true);
    let fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        e.target.files[0],
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          console.log(uri);
          axios
            .post(
              `${process.env.REACT_APP_REST_ENDPOIT}/uploadimages`,
              { image: uri },
              {
                headers: {
                  authtoken: state.user.token,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              console.log('Cloudinary upload', response);

              // setValues to parent component based on either its
              // used for single/multiple upload
              if (singleUpload) {
                // single upload
                const { image } = values;
                setValues({ ...values, image: response.data });
              } else {
                const { images } = values;
                setValues({ ...values, images: [...images, response.data] });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log('Cloudinary upload failed', error);
            });
          //  console.log(uri);
        },
        'base64'
      );
    }
  };

  const handleImageRemove = (id) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_REST_ENDPOIT}/removeimage`,
        {
          public_id: id,
        },
        {
          headers: {
            authtoken: state.user.token,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        // setValues to parent component based on either its
        // used for single/multiple upload

        if (singleUpload) {
          const { image } = values;

          setValues({
            ...values,
            image: {
              url: '',
              public_id: '',
            },
          });
        } else {
          const { images } = values;
          let filteredImages = images.filter((item) => {
            return item.public_id !== id;
          });
          setValues({ ...values, images: filteredImages });
        }
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <label className="btn btn-primary btn-sm">
      Změnit foto
      <input
        hidden
        type="file"
        accept="image/*"
        onChange={fileResizeAndUpload}
        className="form-control"
        placeholder="Image"
        disabled={loading}
      />
    </label>
  );
};

export default ImageUpload;
