import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SINGLE_POST } from '../graphql/queries/post/Post';
import { POST_UPDATE } from '../graphql/mutations/post/post';
import { useParams } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import FileUpload from './forms/FileUpload';
import {
  druhOptions,
  stupenOptions,
  skupinaOptions,
} from '../data/FormsOptions';

const ProjectUpdate = () => {
  const [values, setValues] = useState({
    _id: '',
    image: {
      url: 'https://via.placeholder.com/200x200.png?text=Projekt',
      public_id: '123',
    },
    images: [],
    projekt: '',
    stupen: '',
    druh: '',
    misto: '',
    investor: '',
    skupina: [],
    mnozstvi: '',
  });
  const [getSinglePost, { data: singlePost }] = useLazyQuery(SINGLE_POST);
  const [postUpdate] = useMutation(POST_UPDATE);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  // params.postid , router

  const { postid } = useParams();

  //desctructure

  const {
    _id,
    projekt,
    stupen,
    druh,
    misto,
    investor,
    mnozstvi,
    images,
  } = values;

  useMemo(() => {
    if (singlePost) {
      setValues({
        ...values,
        _id: singlePost.singlePost._id,
        image: singlePost.singlePost.image,
        misto: singlePost.singlePost.misto,
        images: singlePost.singlePost.images,
        projekt: singlePost.singlePost.projekt,
        stupen: singlePost.singlePost.stupen,
        druh: singlePost.singlePost.druh,
        investor: singlePost.singlePost.investor,
        mnozstvi: singlePost.singlePost.mnozstvi,
      });
      setSelected(singlePost.singlePost.skupina);
    }
  }, [singlePost]);

  useEffect(() => {
    getSinglePost({ variables: { postId: postid } });
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let payout = { ...values, skupina: selected };

    postUpdate({ variables: { input: payout } });
    setLoading(false);
    toast.success('Projekt aktualizován');
  };

  const updateForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <p className="text-primary font-weight-bold m-0">
            Informace o projektu
          </p>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-lg-6">
              <div className="col">
                {' '}
                <div className="form-group">
                  <label>
                    <strong>Název projektu</strong>
                  </label>
                  <input
                    type="text"
                    name="projekt"
                    value={projekt}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>

              <div className="col">
                {' '}
                <div className="form-group">
                  <label>
                    <strong>Stupeň projektové dokumentace</strong>{' '}
                  </label>
                  <br></br>
                  <select
                    value={stupen}
                    className="form-control"
                    name="stupen"
                    disabled={loading}
                    onChange={handleChange}
                    required="true"
                  >
                    {stupenOptions.map((stupen) => (
                      <option value={stupen.value}>{stupen.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Druh stavby</strong>
                  </label>
                  <br></br>
                  <select
                    value={druh}
                    className="form-control"
                    name="druh"
                    disabled={loading}
                    onChange={handleChange}
                    required="true"
                  >
                    {druhOptions.map((druh) => (
                      <option value={druh.value}>{druh.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Místo stavby</strong>
                  </label>
                  <input
                    type="text"
                    name="misto"
                    value={misto}
                    onChange={handleChange}
                    required={true}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group pt-4">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                   Dokončit úpravu
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Investor</strong>
                  </label>
                  <input
                    type="text"
                    name="investor"
                    value={investor}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Skupina zboží</strong>
                  </label>

                  <MultiSelect
                    options={skupinaOptions}
                    value={selected}
                    onChange={setSelected}
                    labelledBy={'Select'}
                    overrideStrings={{
                      selectSomeItems: 'Vyberte skupinu zboží...',
                      allItemsAreSelected: 'Vybral jste všechny skupiny.',
                      selectAll: 'Vybrat vše',
                      search: 'Vyhledat',
                      clearSearch: 'Hledání',
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Množství přibližné</strong>
                  </label>
                  <input
                    type="text"
                    name="mnozstvi"
                    value={mnozstvi}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>
     
            </div>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div className="row">
      <div className="col-lg-8">
        {loading ? <h4>Nahrávám</h4> : ''} {updateForm()}{' '}
      </div>
      <div className="col-lg-4">
        {' '}
        <div className="card shadow mb-4">
          {' '}
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">
              Přiložené soubory{' '}
            </h6>
          </div>
          <div className="card-body">
            Nahrané soubory {images.length}
            <div className="form-group pt-4">
              <FileUpload
                setValues={setValues}
                setLoading={setLoading}
                values={values}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectUpdate;
