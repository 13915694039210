import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SINGLE_POST } from '../graphql/queries/post/Post';
import { useParams } from 'react-router-dom';
import PostCard from '../components/CardPost';


const ProjectDetails = () => {
  const [values, setValues] = useState({
    content: '',
    image: {
      url: '',
      public_id: '',
    },
    postedBy: {},
    misto: '',
    images: [],
  });
  const [getSinglePost, { data: singlePost }] = useLazyQuery(SINGLE_POST);

  const { postid } = useParams();

  const { content, image, misto } = values;

  useMemo(() => {
    if (singlePost) {
      setValues({
        ...values,
        _id: singlePost.singlePost._id,
        image: singlePost.singlePost.image.url,
        images: [singlePost.singlePost.images],
        postedBy: singlePost.singlePost.postedBy,
        misto: singlePost.singlePost.misto,
        images: singlePost.singlePost.images,
        projekt: singlePost.singlePost.projekt,
        stupen: singlePost.singlePost.stupen,
        druh: singlePost.singlePost.druh,
        misto: singlePost.singlePost.misto,
        investor: singlePost.singlePost.investor,
        skupina: singlePost.singlePost.skupina,
        mnozstvi: singlePost.singlePost.mnozstvi,
        credit: singlePost.singlePost.credit,
        date: singlePost.singlePost.date
      });
    }
  }, [singlePost]);

  useEffect(() => {
    getSinglePost({ variables: { postId: postid } });
  }, []);

  return <PostCard post={values} />;
};

export default ProjectDetails;
