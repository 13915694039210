import React from 'react';

const Image = ({ image, handleImageRemove = (f) => f}) => (
  
   <div>  <span>Odeberte kliknutím na dokument</span>
  <img
    src={image.url}
    key={image.public_id}
    alt={image.public_id}
    style={{ height: '100px' }}
    className="img-thumbnail m-3"
    onClick={() => handleImageRemove(image.public_id)}
    onError={(e) => {
      e.target.onerror = null;
      e.target.src =
        'https://via.placeholder.com/200x200.png?text=Dokument';
    }}
  />  </div> 
);

export default Image;
