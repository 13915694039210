import React from 'react';

import { useQuery } from '@apollo/client';

import { ALL_USERS_All_REWARDS_LIST } from '../graphql/queries/user/Profile';

const AdminRewardsListTable = () => {
  const { data: rewards } = useQuery(ALL_USERS_All_REWARDS_LIST);

  return (
    <div>
      {rewards &&
        rewards.allRewards.map((r) => {
          return r.rewards.map((e) => (
            <p>
              {' '}
              {r.jmeno} {r.prijmeni} {r.firma} | {e.gift}
            </p>
          ));
        })}
    </div>
  );
};

export default AdminRewardsListTable;
