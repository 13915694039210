import React from 'react';
import Heading from '../components/Heading';
import AdminUsersListTable from '../components/AdminUsersListTable';

const AdminUsersList = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Seznam Uživatelů'} />
      <AdminUsersListTable />
    </div>
  );
};

export default AdminUsersList;
