import React, { useState } from 'react';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';
import AuthForm from '../../components/forms/AuthForm';

const PasswordForgot = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      url: process.env.REACT_APP_PASSWORD_FORGOT_REDIRECT,
      handleCodeInApp: true,
    };

    await auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        setEmail('');
        setLoading(false);
        toast.success(
          `Odesláno na email ${email}. Klikněte na odkaz pro získaní hesla`
        );
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${email} nebyl nalezen`);
      });
  };

  return (

      <AuthForm
        email={email}
        setEmail={setEmail}
        loading={loading}
        handleSubmit={handleSubmit}
        hideGoogleInput="true"
        akce='Zaslat heslo'
      />
   
  );
};

export default PasswordForgot;
