import React from 'react';
import Heading from '../components/Heading';
import AdminRewardsListTable from '../components/AdminRewardsListTable';

const AdminRewardsList = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Seznam žádosti o odměny'} />
      <AdminRewardsListTable />
    </div>
  );
};

export default AdminRewardsList; 
