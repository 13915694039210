import * as firebase from 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: 'AIzaSyDmDI7fdC3QZORnZwAPEN8KpsjCgptm-Ss',
  authDomain: 'schmachtlplus.firebaseapp.com',
  //databaseURL: 'https://schmachtlplus.firebaseio.com',
  projectId: 'schmachtlplus',
  storageBucket: 'schmachtlplus.appspot.com',
  // messagingSenderId: '985391356299',
  appId: '1:985391356299:web:e7faa90eb5c6268559f78e',
  measurementId: 'G-6ELCZ2VHHE',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().languageCode = 'cs';


export default firebase;

export const auth = firebase.auth()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
export const FacebookAuthProvider = new firebase.auth.FacebookAuthProvider()
