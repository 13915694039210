import { gql } from '@apollo/client';

export const POST_CREATE = gql`
  mutation postCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      _id
      image {
        url
        public_id
      }
      images {
        url
        public_id
      }
      postedBy {
        _id
        username
        prijmeni
      }
      projekt
      stupen
      druh
      misto
      investor
      skupina {
        label
        value
      }
      mnozstvi
      date
    }
  }
`;

export const POST_DELETE = gql`
  mutation postDelete($postId: String!) {
    postDelete(postId: $postId) {
      _id
    }
  }
`;

export const POST_UPDATE = gql`
  mutation postUpdate($input: PostUpdateInput!) {
    postUpdate(input: $input) {
      _id
      image {
        url
        public_id
      }
      images {
        url
        public_id
      }
      projekt
      stupen
      druh
      misto
      investor
      skupina {
        label
        value
      }
      mnozstvi
    }
  }
`;
