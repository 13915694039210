import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const LoadingToRedirect = ({ path }) => {
  const [count, setCount] = useState(5);

  let history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 0);

    count === 0 && history.push(path);

    return () => clearInterval(interval)
  }, [count]);

  return (
<div className="spinner-border text-primary loader" role="status" >
  <span className="sr-only">Nahrávám...</span>
</div>
  );
};

export default LoadingToRedirect;