import React, { useState } from 'react';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';
import AuthForm from './AuthForm';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    auth.currentUser
      .updatePassword(password)
      .then(() => {
        setLoading(false);
        toast.success('Heslo aktualizováno');
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error('Heslo musí mít minimálně 6 znaků');
      });
  };
  return (
    <div className="container p-5">
      {loading && (
        <div className="spinner-border text-primary loader-center" role="status">
          <span className="sr-only">Nahrávám...</span>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {!loading && (
          <div>
            <div className="form-group">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control form-control-user"
                placeholder="Nové heslo"
                disabled={loading}
              />
            </div>{' '}
            <button className="btn btn-primary btn-block text-white btn-user">
              Nastavit heslo
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;
