import React from 'react';
import TopBar from './TopBar';
import SideBarWithNav from './SideBarWithNav'

const Wrapper = ({ children }) => {
  return (
    <div id="wrapper">
      <SideBarWithNav />
      <div className="d-flex flex-column" id="content-wrapper">
        <div id="content">
          <TopBar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
