import { Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute'
import NotFoundPage from './NotFoundPage';
import Home from '../pages/Home';
import Register from '../pages/Register';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import AddProject from '../pages/AddProject';
import ProjectsList from '../pages/ProjectsList';
import AdminProjectsList from '../pages/AdminProjectsList';
import AdminRewardsList from '../pages/AdminRewardsList';
import AdminUsersList from '../pages/AdminUsersList'
import Project from '../pages/Project';
import UpdateProject from '../pages/UpdateProject';
import RegistrationComplete from '../pages/RegistrationComplete';
import ForgotPassword from '../pages/ForgotPassword'
import Rewards from '../pages/Rewards'
import Wrapper from '../layouts/Wrapper';

const Routes = () => (
  <Switch>
    <PublicRoute exact path="/" component={Home} />
    <PublicRoute exact path="/registrace" component={Register} />
    <Route exact path="/dokoncit-registraci" component={RegistrationComplete} />
    <Route exact path="/heslo/zapomenuto" component={ForgotPassword} />
    <Wrapper>
      <PrivateRoute exact path="/nastenka" component={Dashboard} />
      <PrivateRoute exact path="/uzivatel" component={Profile} />
      <PrivateRoute exact path="/pridat-projekt" component={AddProject} />
      <PrivateRoute
        exact
        path="/upravit/projekty/:postid"
        component={UpdateProject}
      />
      <PrivateRoute exact path="/me-projekty" component={ProjectsList} />
      <PrivateRoute exact path="/projekt/:postid" component={Project} />
      <PrivateRoute exact path="/odmeny" component={Rewards} />
      <AdminRoute exact path="/projekty-seznam-vse" component={AdminProjectsList} />
      <AdminRoute exact path="/uzivatele-seznam" component={AdminUsersList} />
      <AdminRoute exact path="/odmeny-zadosti" component={AdminRewardsList} />
    </Wrapper>
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;

/* }
<Route exact path="/uzivatele" component={Users} />
<PublicRoute exact path="/registrace" component={Register} />
<PublicRoute exact path="/prihlaseni" component={Login} />
<Route
  exact
  path="/dokoncit-registraci"
  component={CompleteRegistration}
/>
<Route exact path="/heslo/zapomenuto" component={PasswordForgot} />
<PrivateRoute exact path="/heslo/zmenit" component={PasswordUpdate} />
<PrivateRoute exact path="/uzivatel" component={Profile} />
<PrivateRoute exact path="/vlozit/projekty" component={Post} />
<PrivateRoute
  exact
  path="/upravit/projekty/:postid"
  component={PostUpdate}
/>
<Route exact path="/uzivatel-detail/:username" component={SingleUser} />
<Route exact path="/projekt/:postid" component={SinglePost} />
<Route exact path="/vyhledat/:query" component={SearchResult} /> 

*/
