import { gql } from '@apollo/client';

export const GET_ALL_POSTS = gql`
  query($page: Int!) {
    allPosts(page: $page) {
      _id
      image {
        url
        public_id
      }
      postedBy {
        _id
        username
        prijmeni
      }
      projekt
      stupen
      druh
      misto
      investor
      skupina {
        value
        label
      }
      mnozstvi
    }
  }
`;

export const POSTS_BY_USER = gql`
  query {
    postsByUser {
      _id
      image {
        url
        public_id
      }
      postedBy {
        _id
        username
        prijmeni
      }
      projekt
      stupen
      druh
      misto
      investor
      skupina {
        value
        label
      }
      mnozstvi
      credit
    }
  }
`;

export const SINGLE_POST = gql`
  query singlePost($postId: String!) {
    singlePost(postId: $postId) {
      _id
      image {
        url
        public_id
      }
      postedBy {
        _id
        username
        prijmeni
      }
      misto
      images {
        url
        public_id
      }
      projekt
      credit
      stupen
      druh
      misto
      investor
      skupina {
        value
        label
      }
      date
      mnozstvi
    }
  }
`;

export const TOTAL_POSTS = gql`
  query {
    totalPosts
  }
`;

export const TOTAL_POSTS_BY_USER = gql`
  query {
    totalPostsByUser
  }
`;

export const SEARCH = gql`
  query search($query: String!) {
    search(query: $query) {
      _id
      image {
        url
        public_id
      }
      postedBy {
        _id
        username
        prijmeni
      }
    }
  }
`;
