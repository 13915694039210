import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Heading from '../components/Heading';
import Bosh from '../assets/img/bosh.png';
import Extol from '../assets/img/extol.png';
import Makita from '../assets/img/makita.jpg';
import Letherman from '../assets/img/letherman.jpg';
import BlackDecker from '../assets/img/black-decker.png';
import NacoBoost from '../assets/img/nacoboost.jpg';
import Sroubovak from '../assets/img/sroubovak.png';
import { CREDIT } from '../graphql/queries/credit/Credit';
import { USER_ALL_REWARDS_LIST } from '../graphql/queries/user/Profile';
import { useQuery, useMutation } from '@apollo/client';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { USER_GET_REWARD } from '../graphql/mutations/user/userupdate';

const Rewards = () => {
  const [values, setValues] = useState({
    gift: '',
    date: '',
    cost: '',
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  ///const handleShow = () => setShow(true);

  const { data, loading, error } = useQuery(CREDIT);
  const { data: list, loadding, errorr } = useQuery(USER_ALL_REWARDS_LIST);

  const [userReward] = useMutation(USER_GET_REWARD, {
    refetchQueries: [
      {
        query: USER_ALL_REWARDS_LIST
 
      },
      {
        query: CREDIT

      }
    ],

    update: ({ data }) => {
      console.log('Aktualizovana data', data);
      toast.success('Žadost byla přijata');
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);

    userReward({ variables: { input: values } });
    setShow(false);
  };

  const handleClick = (name, price) => {
    setValues({
      ...values,
      gift: name,
      date: '55',
      cost: price,
    });

    setShow(true);
  };

  return (
    <div className="container-fluid">
      <Heading title={'Odměny'} />
      <div className="row">
        <div className="col-md-6 col-xl-8 mb-4">
          <div className="card shadow border-left-primary py-2">
            <div className="card-body">
              <div className="row align-items-center no-gutters">
                <div className="col mr-2">
                  <div
                    className={
                      'text-uppercase text-primary font-weight-bold text-xs mb-1'
                    }
                  >
                    <span>Systém odměn</span>
                  </div>
                  <div>
                    <p>
                      Jsme rádi za zájem o produkty firmy Schmachtl. Snažíme se
                      pro Vás pravidelně aktualizovat knihovnu katalogů, aby
                      jste vždy našli nejnovější produktovou zřadu z naší
                      nabídky. Připravili jsme pro Vás i projektovou sekci, kde
                      si každý postupně sbírá body a v okamžiku, kdy uzná za
                      vhodné, může si tyto body směnit. Seznam odměn bude
                      pravidelně aktualizován.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 mb-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    BOSCH Professional 5dílná sada vrtáků do betonu
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={Bosh}
                    width={200}
                    height={200}
                  />

                  <h5>
                    <strong>100 bodů </strong>
                  </h5>
                  <button
                    disabled={data.postsCreditByUser >= 100 ? false : true}
                    onClick={(e) =>
                      handleClick(
                        'BOSCH Professional 5dílná sada vrtáků do betonu',
                        100
                      )
                    }
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>
            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Extol Premium Kufr na nářadí, 462x256x242mm
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={Extol}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>200 bodů </strong>
                  </h4>
                  <button
                    disabled={data.postsCreditByUser >= 200 ? false : true}
                    onClick={(e) =>
                      handleClick(
                        'Extol Premium Kufr na nářadí, 462x256x242mm',
                        200
                      )
                    }
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>

            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Sada vrtáků Makita 99 ks
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={Makita}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>300 bodů </strong>
                  </h4>
                  <button
                    onClick={(e) =>
                      handleClick('Sada vrtáků Makita 99 ks', 300)
                    }
                    disabled={data.postsCreditByUser >= 300 ? false : true}
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>

            <div className="col-lg-4 mb-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Ruční akumulátorový šroubovák + sada bitů
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={Sroubovak}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>400 bodů </strong>
                  </h4>
                  <button
                    onClick={(e) =>
                      handleClick(
                        'Ruční akumulátorový šroubovák + sada bitů',
                        400
                      )
                    }
                    disabled={data.postsCreditByUser >= 400 ? false : true}
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>

            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Black+Decker akuvrtačka 18V s náhradní baterií
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={BlackDecker}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>500 bodů </strong>
                  </h4>
                  <button
                    onClick={(e) =>
                      handleClick(
                        'Black+Decker akuvrtačka 18V s náhradní baterií',
                        500
                      )
                    }
                    disabled={data.postsCreditByUser >= 500 ? false : true}
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>
            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Leatherman REV
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={Letherman}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>600 bodů </strong>
                  </h4>
                  <button
                    onClick={(e) =>
                      handleClick(
                        'Black+Decker akuvrtačka 18V s náhradní baterií',
                        600
                      )
                    }
                    disabled={data.postsCreditByUser >= 600 ? false : true}
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>
            <div className="col-lg-4 ">
              <div className="card mb-3">
                <div className="card-header  text-center py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Noco Startovací Zdroj Gb40
                  </h6>
                </div>
                <div className="card-body text-center shadow">
                  <img
                    className=" mb-3 mt-4"
                    src={NacoBoost}
                    width={200}
                    height={200}
                  />

                  <h4>
                    {' '}
                    <strong>700 bodů </strong>
                  </h4>
                  <button
                    onClick={(e) =>
                      handleClick(
                        'Black+Decker akuvrtačka 18V s náhradní baterií',
                        700
                      )
                    }
                    disabled={data.postsCreditByUser >= 700 ? false : true}
                    className="mx-auto mt-3 btn btn-primary btn-block text-white btn-user w-50"
                  >
                    Ziskat odměnu
                  </button>
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          {' '}
          <div className={'card shadow border-left-success py-2'}>
            <div className="card-body">
              <div className="row align-items-center no-gutters">
                <div className="col mr-2">
                  <div
                    className={
                      'text-uppercase text-primary font-weight-bold text-xs mb-1'
                    }
                  >
                    <span>Váš stav bodů</span>
                  </div>
                  <p>
                    <strong>{data.postsCreditByUser}</strong>
                  </p>

                  <div
                    className={
                      'text-uppercase text-primary font-weight-bold text-xs mb-1'
                    }
                  >
                    <span>Jak se počítají body?</span>
                  </div>
                  <ul className="list-unstyled">
                    <li>Registrace 50 bodů</li>
                    <li>Přidání projektu 30 bodů</li>
                    <li>Příloha k projektu 10 bodů </li>
                  </ul>

                  <div
                    className={
                      'text-uppercase text-primary font-weight-bold text-xs mb-1'
                    }
                  >
                    <span>Historie vašich odměn</span>
                  </div>
                  <ul class="list-unstyled">
                    {list &&
                      list.profile.rewards.map((e) => {
                        return (
                          <li>
                            {e.cost} - {e.gift}
                          </li>
                        );
                      })}

                    <li> </li>
                  </ul>
                </div>
                <div className="col-auto">
                  <i className="gh" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Gratulujeme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Přejete si získat, tento výrobek? Náš obchodní zástupce Vám ho rád
          osobně předá.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Objednat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Rewards;
