import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { IS_ADMIN } from '../../graphql/queries/user/Profile';
import { useQuery } from '@apollo/client';

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const { data } = useQuery(IS_ADMIN);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const signOutApp = () => {
    auth
      .signOut()
      .then(function () {})
      .catch(function (error) {
        // An error happened.
      });
  };

  const adminMenu = () => {
    return (
      <div className="mt-5">
        <p className="text-white text-center mt-1">
          <strong>ADMIN MENU</strong>
        </p>
        <ul className="nav navbar-nav text-light" id="accordionSidebar">
          <li className="nav-item">
            <Link className="nav-link" to="/projekty-seznam-vse">
              <i className="fa fa-list"></i>
              <span>Seznam projektu</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/uzivatele-seznam">
              <i className="fa fa-users"></i>
              <span>Seznam uživatelů</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/odmeny-zadosti">
              <i className="fa fa-star"></i>
              <span>Žádosti o odměnu</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
      <div className="container-fluid d-flex flex-column p-0">
        <a
          className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
          href="#"
        >
          <div className="sidebar-brand-text mx-3">
            <span>Schmachtl</span>
          </div>
        </a>

        <hr className="sidebar-divider my-0" />
        <ul className="nav navbar-nav text-light" id="accordionSidebar">
          <li className="nav-item">
            <Link className="nav-link active" to="/nastenka">
              <i className="fas fa-tachometer-alt"></i>
              <span>Nástěnka</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/uzivatel">
              <i className="fas fa-user"></i>
              <span>Uživatel</span>
            </Link>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample09"
              aria-controls="navbarsExample09"
              aria-expanded={!isNavCollapsed ? true : false}
              aria-label="Toggle navigation"
              onClick={handleNavCollapse}
            >
              <i className="fa fa-inbox"></i>
              <span>Projekty</span>
            </div>
            <div class="w-20 pl-3">
              <div
                class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
                id="navbarsExample09"
              >
                <Link className="nav-link" to="/pridat-projekt">
                  <i className="fa fa-plus"></i>
                  <span>Přídat projekt</span>
                </Link>
                <Link className="nav-link" to="/me-projekty">
                  <i className="fa fa-folder"></i>
                  <span>Mé projekty</span>
                </Link>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/odmeny">
              <i className="fa fa-gift"></i>
              <span>Odměny</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              onClick={(e) => signOutApp()}
              className="nav-link"
              href="register.html"
            >
              <i className="fas fa-user-circle"></i>
              <span>Odhlášení</span>
            </a>
          </li>
        </ul>

        {data && data.isAdmin.level === 1 ? adminMenu() : null}
        <div className="text-center d-none d-md-inline">
          <button
            className="btn rounded-circle border-0"
            id="sidebarToggle"
            type="button"
          ></button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
