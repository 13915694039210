import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { gql, useMutation } from '@apollo/client';
import AuthForm from '../../components/forms/AuthForm';

const USER_CREATE = gql`
  mutation userCreate {
    userCreate {
      username
      email
    }
  }
`;

const CompleteRegistration = () => {
  const { dispatch } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setEmail(window.localStorage.getItem('emailForRegistration'));
  }, [history]);

  const [userCreate] = useMutation(USER_CREATE);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    ///validation

    if (!email || !password) {
      setLoading(false);
      toast.error('Email a heslo je povinné');

      return;
    }

    if (password.length < 6) {
      setLoading(false);
      toast.error('Heslo musí mít minimálně 6 znáků');

      return;
    }

    try {
      const result = await auth.signInWithEmailLink(
        email,
        window.location.href
      );

      // console.log(result)

      if (result.user.emailVerified) {
        // remove email from local storage
        window.localStorage.removeItem('emailForRegistration');
        let user = auth.currentUser;
        await user.updatePassword(password);

        // dispatch user with token and email

        // then redirect

        const idTokenResult = await user.getIdTokenResult();
        dispatch({
          type: 'LOGGED_IN_USER',
          payload: { email: user.email, token: idTokenResult.token },
        });

        // make api request to save/update user in mongodb

        userCreate();

        history.push('/nastenka');
      }
    } catch (error) {
      console.log('chyba registrace', error.message);
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (

         <AuthForm  email={email} setEmail={setEmail} setPassword={setPassword} password={password} loading={loading} handleSubmit={handleSubmit} showPasswordInput='true' akce='Dokončit registraci' hideEmailInput="true" hideGoogleInput="true" showTextEmail="true" textBottomForm={<p className="text-center"><small>Heslo musí obsahovat minimálně 6 znaků</small> </p>} />
   
  );
};

export default CompleteRegistration;
