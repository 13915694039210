import React from 'react';
import Heading from '../components/Heading';
import UserProjectsTable from '../components/UserProjectsTable';

const Projects = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Seznam projektů'} />
      <UserProjectsTable />
    </div>
  );
};

export default Projects;
