import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/authContext';
import { Link, useHistory } from 'react-router-dom';
import { auth, googleAuthProvider, FacebookAuthProvider } from '../../firebase';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import AuthForm from '../../components/forms/AuthForm';
const USER_CREATE = gql`
  mutation userCreate {
    userCreate {
      username
      email
    }
  }
`;

const Login = () => {
  const { dispatch } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const [userCreate] = useMutation(USER_CREATE);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await auth
        .signInWithEmailAndPassword(email, password)
        .then(async (result) => {
          const { user } = result;
          const idTokenResult = await user.getIdTokenResult();
          dispatch({
            type: 'LOGGED_IN_USER',
            payload: { email: user.email, token: idTokenResult.token },
          });

          // send user info to our server mongodb to either update/create

          userCreate();

          history.push('/uzivatel');
        });
    } catch (error) {
      console.log('login error', error);
      toast.error('Nesprávné heslo nebo email');
      setLoading(false);
    }
  };

  const googleLogin = () => {
    auth
      .signInWithPopup(googleAuthProvider)

      .then(async (result) => {
        const { user } = result;
        const idTokenResult = await user.getIdTokenResult();

        dispatch({
          type: 'LOGGED_IN_USER',
          payload: { email: user.email, token: idTokenResult.token },
        });

        // send user info to our server mongodb to either update/create
        userCreate();
        history.push('/uzivatel');
      });
  };

  return (
    <AuthForm
      googleLogin={googleLogin}
      email={email}
      setEmail={setEmail}
      setPassword={setPassword}
      password={password}
      loading={loading}
      handleSubmit={handleSubmit}
      showPasswordInput="true"
      akce="Přihlásit se"
    />
  );
};

export default Login;

/*
     <AuthForm
        email={email}
        setEmail={setEmail}
        setPassword={setPassword}
        password={password}
        loading={loading}
        handleSubmit={handleSubmit}
        showPasswordInput="true"
        akce="Přihlásit se"
      />
      */
