import React from 'react';

import { useQuery } from '@apollo/client';

import { ALL_USERS_LIST } from '../graphql/queries/user/Profile';
const moment = require('moment');

const AdminUsersListTable = () => {
  const { data: users } = useQuery(ALL_USERS_LIST);

  console.log(users);
  return (
    <div>
      <ul class="list-unstyled">
        {users &&
          users.allUsers.map((user) => (
            <li>
              
              {user.jmeno} {user.prijmeni}    {user.email}{' '}
              <span class="text-primary text-capitalize "> {user.firma} </span>{' '}
              <span class="text-capitalize font-weight-bold"> {user.obchodak} </span>
           
            </li>
          ))}
      </ul>

      <h4>Celkový počet: {users && users.allUsers.length}</h4>
     
    </div>
  );
};

export default AdminUsersListTable;
