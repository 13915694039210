import React from 'react';

const AuthForm = ({
  email = '',
  password = '',
  loading,
  setEmail = (f) => f,
  setPassword,
  handleSubmit,
  showPasswordInput = false,
  showTextEmail = false,
  akce = 'Přihlásit se',
  hideEmailInput = false,
  hideGoogleInput = false,
  textBottomForm = null,
  googleLogin = (f) => f,
}) => (
  <form className="user" onSubmit={handleSubmit}>
    {!hideEmailInput && (
      <div className="form-group">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control form-control-user"
          placeholder="Vložte email"
          disabled={loading}
        />
      </div>
    )}{' '}

  {showTextEmail && <p className="text-center"> {email} </p>} 

    {showPasswordInput ? (
      <div className="form-group">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control form-control-user"
          placeholder="Vaše heslo"
          required={true}
          disabled={loading}
        />
      </div>
    ) : null}
   
    {textBottomForm}
    <button
      disabled={!email}
      className="btn btn-primary btn-block text-white btn-user"
    >
      {akce}
    </button>
    {!hideGoogleInput && (
      <a
        className="btn btn-primary btn-block text-white btn-google btn-user"
        role="button"
        onClick={googleLogin}
      >
        <i className="fab fa-google" />
        &nbsp; {akce} přes Google
      </a>
    )}
    <hr />
  </form>
);

export default AuthForm;

/*  <form className="user" onSubmit={handleSubmit}>
    {!hideEmailInput && (
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control-user"
          placeholder="Vložte email"
          disabled={loading}
        />
      </div>
    )}

    {showPasswordInput ? (
      <div className="form-group">
        <label>Heslo</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control"
          placeholder="Vaše heslo"
          disabled={loading}
        />
      </div>
    ) : null}

    <button className="btn btn-raised btn-primary" disabled={loading}>
      {akce}
    </button>
  </form>
  */
