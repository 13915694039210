import { gql } from '@apollo/client';

export const USER_UPDATE = gql`
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      _id
      username
      email

      image {
        url
        public_id
      }
      firma
      jmeno
      prijmeni
      ulice
      mesto
      psc
      telefon
      obchodak
      createdAt
      updatedAt
    }
  }
`;

export const USER_GET_REWARD = gql`
  mutation userReward($input: UserRewardPostInput!) {
    userReward(input: $input) {
      gift
      date
      cost
    }
  }
`;
