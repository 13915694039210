import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { POST_DELETE } from '../graphql/mutations/post/post';
import { POSTS_BY_USER } from '../graphql/queries/post/Post';
import { CREDIT } from '../graphql/queries/credit/Credit';

const UserProjectsTable = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data: posts } = useQuery(POSTS_BY_USER);

  const [postDelete] = useMutation(POST_DELETE, {
    update: ({ data }) => {
      console.log('POST DELETE MUTATION,', data);
      toast.error('Projekt odebrán');
    },
    onError: (err) => {
      console.log(err);

      toast.error('Chyba');
    },
  });

  const handleDelete = async (postId) => {
    let answer = window.confirm('Vymazat?');
    if (answer) {
      setLoading(true);
      postDelete({
        variables: { postId },
        refetchQueries: [{ query: POSTS_BY_USER }, { query: CREDIT }],
      });
      setLoading(false);
    }
  };

  const table = () => (
    <div className="card shadow">
      <div className="card-header py-3">
        <p className="text-primary m-0 font-weight-bold">Seznam projektu</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 text-nowrap">
            <div
              id="dataTable_length"
              className="dataTables_length"
              aria-controls="dataTable"
            >
              <label>
                Zobrazit&nbsp;
                <select className="form-control form-control-sm custom-select custom-select-sm">
                  <option value={10} selected>
                    10
                  </option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                &nbsp;
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="text-md-right dataTables_filter"
              id="dataTable_filter"
            >
              <label>
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Vyhledat"
                />
              </label>
            </div>
          </div>
        </div>
        <div
          className="table-responsive table mt-2"
          id="dataTable"
          role="grid"
          aria-describedby="dataTable_info"
        >
          <table className="table my-0" id="dataTable">
            <thead>
              <tr>
                <th>Název projektu</th>
                <th>Místo stavby</th>
                <th>Druh stavby</th>
                <th>Skupina zboží</th>
                <th>Investor</th>
                <th>Množství</th>
                <th>Body</th>
                <th>Akce</th>
              </tr>
            </thead>
            <tbody>
              {posts &&
                posts.postsByUser.map((post) => (
                  <tr key={post._id}>
                    <td>
                      <img
                        className="rounded-circle mr-2"
                        width={30}
                        height={30}
                        src={post.image.url}
                      />
                      <Link
                        className="text-decoration-none"
                        to={`/projekt/${post._id}`}
                      >
                        {' '}
                        {post.projekt}{' '}
                      </Link>
                    </td>
                    <td>{post.misto}</td>
                    <td>{post.druh}</td>
                    <td><span className="text-limit">
                      {post.skupina &&
                        post.skupina.map((g) => (
                          <span key={g._id}>{g.value} </span>
                        ))}
                        </span> </td>

                    <td>{post.investor}</td>
                    <td>{post.mnozstvi} </td>
                    <td>{post.credit} </td>
                    

                    <td>
                      <span className="cursor-pointer pr-2">
                        <i
                          onClick={() =>
                            history.push(`/upravit/projekty/${post._id}`)
                          }
                          className="fa fa-edit text-primary"
                        ></i>
                      </span>

                      <span className="cursor-pointer">
                        <i
                          onClick={() => handleDelete(post._id)}
                          className="fa fa-trash text-danger"
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>Název projektu</strong>
                </td>
                <td>
                  <strong>Místo stavby</strong>
                </td>
                <td>
                  <strong>Druh stavby</strong>
                </td>
                <td>
                  <strong>Skupina zboží</strong>
                </td>
                <td>
                  <strong>Investor</strong>
                </td>
                <td>
                  <strong>Množství</strong>
                </td>
                <td>
                  <strong>Body</strong>
                </td>
                <td>
                  <strong>Akce</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>{' '}
        {/*
        <div className="row">
          <div className="col-md-6 align-self-center">
            <p
              id="dataTable_info"
              className="dataTables_info"
              role="status"
              aria-live="polite"
            >
              Zobrazit 1 z 10 celkově 27
            </p>
          </div>
          <div className="col-md-6">
            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className="page-item disabled">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>*/}
      </div>
    </div>
  );

  return <div>{table()}</div>;
};

export default UserProjectsTable;
