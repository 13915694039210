import React, { Fragment } from 'react';

const UserProfile = ({
  handleSubmit,
  handleChange,
  username,
  email,
  firma,
  jmeno,
  prijmeni,
  ulice,
  mesto,
  psc,
  telefon,
  obchodak,
  loading,
}) => (
  <Fragment>
    {' '}
    <div className="row">
      <div className="col">
      <form onSubmit={handleSubmit}>
          <div className="card shadow mb-3">
            <div className="card-header py-3">
              <p className="text-primary m-0 font-weight-bold">Uživatel</p>
            </div>
            <div className="card-body">
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="jmeno">
                      <strong>Jméno</strong>
                    </label>
                    <input
                      type="text"
                      name="jmeno"
                      value={jmeno}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="prijmeni">
                      <strong>Příjmení</strong>
                    </label>
                    <input
                      type="text"
                      name="prijmeni"
                      value={prijmeni}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="firma">
                      <strong>Název projekční firmy</strong>
                    </label>
                    <input
                      type="text"
                      name="firma"
                      value={firma}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor="obchodní">
                      <strong>Obchodní zástupce</strong>
                    </label>
                    <select
                      value={obchodak}
                      className="form-control"
                      name="obchodak"
                      disabled={loading}
                      onChange={handleChange}
                    >
                      <option value="bosko">Boško</option>
                      <option value="franek">Franěk</option>
                      <option value="los">Los</option>
                      <option value="mach">Mach</option>
                      <option value="novosad">Novosad</option>
                      <option value="slavik">Slavík</option>
                      <option value="sulik">Šulík</option>
                      <option value="svarovsky">Svarovský</option>


                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mb-3">
            <div className="card-header py-3">
              <p className="text-primary m-0 font-weight-bold">Kontakt</p>
            </div>
            <div className="card-body">
              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="ulice">
                      <strong>Ulice</strong>
                    </label>
                    <input
                      type="text"
                      name="ulice"
                      value={ulice}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="mesto">
                      <strong>Město</strong>
                    </label>
                    <input
                      type="text"
                      name="mesto"
                      value={mesto}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
     
              </div>
              <div className="form-row">

              <div className="col">
                  <div className="form-group">
                    <label htmlFor="psc">
                      <strong>PSČ</strong>
                    </label>
                    <input
                      type="text"
                      name="psc"
                      value={psc}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
         
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="telefon">
                      <strong>Telefon</strong>
                    </label>
                    <input
                      type="tel"
                      name="telefon"
                      value={telefon}
                      onChange={handleChange}
                      className="form-control"
                      placeholder=""
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
              <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="email"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!email || loading}
                >
                  Uložit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Fragment>
);

export default UserProfile;
