import { useQuery } from '@apollo/client';
import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { ALLCATALOGS } from '../graphql/queries/catalogs/Catalogs';

const Catalogs = () => {
  const { data } = useQuery(ALLCATALOGS);

  const catalogsFilter = (category) => {
    let catalogsResult =
      data && data.allCatalogs.filter((data) => data.category === category);

    return catalogsResult;
  };

  const kabeloveNosniSystemy = catalogsFilter('kabelove_nosne_systemy');
  const napajeciZdroje = catalogsFilter('napajeci_zdroje');
  const podlahoveKrabice = catalogsFilter('podlahove_krabice');
  const pripojnicoveSystemy = catalogsFilter('pripojnicove_systemy');
  const zasuvkovaSkupina = catalogsFilter('zasuvkova_skupina');
  const nosneSystemyOdolneProtiKorozi = catalogsFilter(
    'nosne_systemy_odolne_proti_korozi'
  );
  const nerezovePodlahoveKrabice = catalogsFilter('nerezove_podlahove_krabice');
  const plastovePodlahoveKrabice = catalogsFilter('plastove_podlahove_krabice');
  const venkovniPodlahoveKrabice = catalogsFilter('venkovni_podlahove_krabice');
  const podparapetniKanalyHlinikove = catalogsFilter(
    'podparatove_kanaly_hlinikove'
  );
  const podparapetniKanalyOceloplechove = catalogsFilter(
    'podparatove_kanaly_oceloplechove'
  );
  const podparapetniKanalyPlastove = catalogsFilter(
    'podparatove_kanaly_plastove'
  );
  const zalozniZdroje = catalogsFilter('zalozni_zdroje');
  const zalozniZdroje1f = catalogsFilter('zalozni_zdroje_1f');
  const zalozniZdroje3f = catalogsFilter('zalozni_zdroje_3f');
  const zalozniZdrojeModularni = catalogsFilter('zalozni_zdroje_modularni');
  const aros = catalogsFilter('aros');
  const batterX = catalogsFilter('batterx');
  const konektoryFV = catalogsFilter('konektory_fv');
  const referencniProjekty = catalogsFilter('referecni_projekty');
  const wattsonic = catalogsFilter('wattsonic');
  const kaiser = catalogsFilter('kaiser');
  const konektoroveSystemy = catalogsFilter('konektorove_systemy')
  const podlahoveKabeloveZlabi = catalogsFilter('podlahove_kabelove_zlaby')
  const pripojnaMistaMeyer = catalogsFilter('pripojna_mista_meyer')
  const prumysloveZasuvkyVidlice = catalogsFilter('prumyslove_zasuvky_a_vidlice')
  const rozvadecoveSkrine = catalogsFilter('rozvadecove_skrine')
  const vypinaceZasuvky = catalogsFilter('vypinace_zasuvky')
  const protipozarniSkrine = catalogsFilter('protipozarni_skrine')

  return (
    <Accordion>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="801"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Fotovoltaika
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="801">
          {/*      <Card.Body>
            <ul class="list-group list-group-flush">
              {data &&
                kabeloveNosniSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>

            </Accordion.Collapse>
          </Card.Body>*/}

          <Accordion defaultActiveKey="802">
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="803">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Aros
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="803">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      aros.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="804">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    BatterX
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="804">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      batterX.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="805">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Konektory FV
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="805">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      konektoryFV.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="806">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Referenční projekty
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="806">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      referencniProjekty.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="807">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Wattsonic
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="807">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      wattsonic.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="0"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Kabelové nosné systémy
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          {/*      <Card.Body>
            <ul class="list-group list-group-flush">
              {data &&
                kabeloveNosniSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>

            </Accordion.Collapse>
          </Card.Body>*/}

          <Accordion defaultActiveKey="101">
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="102">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Nosné systémy odolné proti korozi
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="102">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      nosneSystemyOdolneProtiKorozi.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Body>
                <ul class="list-group list-group-flush">
                  {data &&
                    kabeloveNosniSystemy.map((katalog) => (
                      <li key={katalog._id} className="list-group-item">
                        <i className="fa fa-file-pdf text-primary"> </i>{' '}
                        <a href={katalog.url} rel="noreferrer" target="_blank">
                          {' '}
                          {katalog.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </Card.Body>
            </Card>
          </Accordion>
        </Accordion.Collapse>
      </Card>

      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="901"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">Kaiser</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="901">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&  
                kaiser.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="1901"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">Konektorové systémy</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1901">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                konektoroveSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="1"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Napájecí zdroje 400Hz
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                napajeciZdroje.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="2901"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">Podlahové kabelové žlaby</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2901">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                podlahoveKabeloveZlabi.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="2"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Podlahové krabice
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          {/*      <Card.Body>
            <ul class="list-group list-group-flush">
              {data &&
                kabeloveNosniSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>

            </Accordion.Collapse>
          </Card.Body>*/}

          <Accordion defaultActiveKey="201">
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="202">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Nerezové podlahové krabice
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="202">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      nerezovePodlahoveKrabice.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="203">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Plastové podlahové krabice
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="203">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      plastovePodlahoveKrabice.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="204">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Venkovní podlahové krabice
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="204">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      venkovniPodlahoveKrabice.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Body>
                <ul class="list-group list-group-flush">
                  {data &&
                    podlahoveKrabice.map((katalog) => (
                      <li key={katalog._id} className="list-group-item">
                        <i className="fa fa-file-pdf text-primary"> </i>{' '}
                        <a href={katalog.url} rel="noreferrer" target="_blank">
                          {' '}
                          {katalog.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </Card.Body>
            </Card>
          </Accordion>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="301"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Podparapetní kanály
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="301">
          {/*      <Card.Body>
            <ul class="list-group list-group-flush">
              {data &&
                kabeloveNosniSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>

            </Accordion.Collapse>
          </Card.Body>*/}

          <Accordion defaultActiveKey="301">
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="302">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Hliníkové
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="302">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      podparapetniKanalyHlinikove.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="303">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Oceloplech
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="303">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      podparapetniKanalyOceloplechove.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="204">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Plastové
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="204">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      podparapetniKanalyPlastove.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="3901"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">Přípojná místa Meyer</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3901">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                pripojnaMistaMeyer.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="4"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Přípojnicové systémy
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                pripojnicoveSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="46545"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
            Protipožární skříně se zachováním funkčnosti zařízení 
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
               protipozarniSkrine.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="450"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Prumyslové zásuvky a vidlice
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="450">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                prumysloveZasuvkyVidlice.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="550"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
             Rozváděčové skříně
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="550">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
               rozvadecoveSkrine.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="650"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
           Vypínače, zásuvky
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="650">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
               vypinaceZasuvky.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="501"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Záložní zdroje
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="501">
          {/*      <Card.Body>
            <ul class="list-group list-group-flush">
              {data &&
                kabeloveNosniSystemy.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>

            </Accordion.Collapse>
          </Card.Body>*/}

          <Accordion defaultActiveKey="501">
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="502">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    1f UPS
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="502">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      zalozniZdroje1f.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="503">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    3f UPS
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="503">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      zalozniZdroje3f.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ backgroundColor: 'white' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="504">
                  <i className="fa fa-folder text-primary"> </i>{' '}
                  <span className="text-decoration-none cursor-pointer">
                    {' '}
                    Modulární
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="504">
                <Card.Body>
                  {' '}
                  <ul class="list-group list-group-flush">
                    {data &&
                      zalozniZdrojeModularni.map((katalog) => (
                        <li key={katalog._id} className="list-group-item">
                          <i className="fa fa-file-pdf text-primary"> </i>{' '}
                          <a
                            href={katalog.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {' '}
                            {katalog.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card.Body>
              {' '}
              <ul class="list-group list-group-flush">
                {data &&
                  zalozniZdroje.map((katalog) => (
                    <li key={katalog._id} className="list-group-item">
                      <i className="fa fa-file-pdf text-primary"> </i>{' '}
                      <a href={katalog.url} rel="noreferrer" target="_blank">
                        {' '}
                        {katalog.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </Card.Body>
          </Accordion>
        </Accordion.Collapse>
      </Card>

      <Card bg={'fff'}>
        <Card.Header style={{ backgroundColor: 'white' }}>
          <Accordion.Toggle
            as="text-primary"
            variant="btn-success"
            eventKey="6"
          >
            <i className="fa fa-folder text-primary"> </i>{' '}
            <span className="text-decoration-none cursor-pointer">
              Zásuvkové skříně
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="6">
          <Card.Body>
            {' '}
            <ul class="list-group list-group-flush">
              {data &&
                zasuvkovaSkupina.map((katalog) => (
                  <li key={katalog._id} className="list-group-item">
                    <i className="fa fa-file-pdf text-primary"> </i>{' '}
                    <a href={katalog.url} rel="noreferrer" target="_blank">
                      {' '}
                      {katalog.name}
                    </a>
                  </li>
                ))}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Catalogs;
