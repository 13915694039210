import React from 'react'

const Box = ({heading, value, icon, color}) => {
    return (
        
        <div className="col-md-6 col-xl-3 mb-4">
          <div className={"card shadow border-left-"+color+  " py-2"}>
            <div className="card-body">
              <div className="row align-items-center no-gutters">
                <div className="col mr-2">
                  <div className={"text-uppercase text-"+color+" font-weight-bold text-xs mb-1"}>
                    <span>{heading}</span>
                  </div>
                  <div className="text-dark font-weight-bold h5 mb-0">
                    <span>{value}</span>
                  </div>
                </div>
                <div className="col-auto">
                  <i className={icon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      
    )
}

export default Box
