import { gql } from '@apollo/client';

export const ALLCATALOGS = gql`
  query allCatalogs {
    allCatalogs {
      name
      url
      category
    }
  }
`;


/*export const CATALOGS_BY_CATEGORY = gql`
  query($category: String!) catalogsByCategory(category: $category) {
    catalogsByCategory {
      name
      url
      category
    }
  }
`;
*/