import React, { useState, useMemo, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { PROFILE, PROFILE_IMAGE } from '../graphql/queries/user/Profile';
import { USER_UPDATE } from '../graphql/mutations/user/userupdate';
import ImageUpload from './forms/ImageUpload';
import UserForm from './forms/UserForm';
import ChangePassword from './forms/ChangePassword';

const User = () => {
  const [values, setValues] = useState({
    username: '',
    email: '',
    image: {
      url: 'https://via.placeholder.com/200x200.png?text=Profil',
      public_id: '123',
    },
    credit: '',
    firma: '',
    jmeno: '',
    prijmeni: '',
    ulice: '',
    mesto: '',
    psc: '',
    telefon: '',
    obchodak: '',
  });

  console.log(values);

  const [loading, setLoading] = useState(false);

  const { data } = useQuery(PROFILE);

  useMemo(() => {
    if (data) {
      console.log(data.profile);
      setValues({
        ...values,
        username: data.profile.username,
        email: data.profile.email,
        image: data.profile.image,
        credit: data.profile.credit,
        firma: data.profile.firma,
        jmeno: data.profile.jmeno,
        prijmeni: data.profile.prijmeni,
        ulice: data.profile.ulice,
        mesto: data.profile.mesto,
        psc: data.profile.psc,
        telefon: data.profile.telefon,
        obchodak: data.profile.obchodak,
      });
    }
  }, [data]);

  // mutation

  const [userUpdate] = useMutation(USER_UPDATE, {
    refetchQueries: [
      {
        query: PROFILE_IMAGE,
      },
    ],

    update: ({ data }) => {
      console.log('Aktualizovana data', data);
      toast.success('Profil aktualizován');
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    setLoading(true);
    userUpdate({ variables: { input: values } });
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <div className="col-lg-4">
        <div className="card mb-3">
          <div className="card-body text-center shadow">
            <img
              className="rounded-circle mb-3 mt-4"
              src={values.image.url}
              width={160}
              height={160}
            />
            <div className="mb-3">
              <ImageUpload
                setValues={setValues}
                setLoading={setLoading}
                values={values}
                loading={loading}
                singleUpload={true}
              />
            </div>
          </div>
        </div>{' '}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">Změnit heslo</h6>
          </div>
          <ChangePassword />
        </div>
      </div>
      <div className="col-lg-8">
        <UserForm
          {...values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </Fragment>
  );
};

export default User;
