import React from 'react';
import { CREDIT } from '../graphql/queries/credit/Credit';
import { useQuery } from '@apollo/client';

const Credit = () => {
  const { data, loading, error } = useQuery(CREDIT);

return <div> {data && data.postsCreditByUser} bodů </div>;
};

export default Credit;
