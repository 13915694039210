export const druhOptions = [
  { value: 'Administrativa', label: 'Administrativa' },
  { value: 'Autosalon', label: 'Autosalon' },
  { value: 'Byty', label: 'Byty' },
  { value: 'Hotely', label: 'Hotely' },
  { value: 'Obchody', label: 'Obchody' },
  { value: 'Ostatní', label: 'Ostatní' },
  { value: 'Průmysl', label: 'Průmysl' },
  { value: 'Sport', label: 'Sport' },
  { value: 'Statní správa', label: 'Statní správa' },
  { value: 'Školství', label: 'Školství' },
  { value: 'Zdravotnictví', label: 'Zdravotnictví' },
];

export const stupenOptions = [
  { value: 'Investiční záměr', label: 'Investiční záměr' },
  {
    value: 'STS/PPR_Studie stavby nebo přípravné práce',
    label: 'STS/PPR_Studie stavby nebo přípravné práce',
  },
  {
    value:
      'DÚR_Dokumentace pro vydání rozhodnutí o umístění stavby (územní rozhodnutí)',
    label:
      'DÚR_Dokumentace pro vydání rozhodnutí o umístění stavby (územní rozhodnutí)',
  },
  {
    value: 'DSP_Dokumentace pro stavební povolení',
    label: 'DSP_Dokumentace pro stavební povolení',
  },
  {
    value: 'DOS_Dokumentace pro ohlášení stavby',
    label: 'DOS_Dokumentace pro ohlášení stavby',
  },
  {
    value: 'DZS – Dokumentace pro zadání stavby',
    label: 'DZS – Dokumentace pro zadání stavby',
  },
  {
    value: 'DPS_Dokumentace pro provádění stavby',
    label: 'DPS_Dokumentace pro provádění stavby',
  },
  {
    value: 'RDS_Realizační dokumentace stavby',
    label: 'RDS_Realizační dokumentace stavby',
  },
  {
    value: 'DSPS (SKP)_Dokumentace skutečného provedení stavby',
    label: 'DSPS (SKP)_Dokumentace skutečného provedení stavby',
  },
];

export const skupinaOptions = [
  { label: 'Konektory Wieland Gesis', value: 'Konektory Wieland Gesis' },
  {
    label: 'Vysoce odolné polyesterové rozvaděče PEDRO IP65',
    value: 'Vysoce odolné polyesterové rozvaděče PEDRO IP65',
  },
  {
    label: 'Plastové podparapetní kanál IBOCO',
    value: 'Plastové podparapetní kanál IBOCO',
  },
  {
    label: 'Plastové podparapetní kanál SIMON',
    value: 'Plastové podparapetní kanál SIMON',
  },
  {
    label: 'Plastové podparapetní kanály GGK',
    value: 'Plastové podparapetní kanály GGK',
  },
  {
    label: 'Hliníkové podparapetní kanály SIMON',
    value: 'Hliníkové podparapetní kanály SIMON',
  },
  {
    label: 'Oceloplechové podparapetní kanály GGK',
    value: 'Oceloplechové podparapetní kanály GGK',
  },
  {
    label: 'Podlahové krabice plastové PUK',
    value: 'Podlahové krabice plastové PUK',
  },
  {
    label: 'Podlahové krabice plastové SIMON',
    value: 'Podlahové krabice plastové SIMON',
  },
  {
    label: 'Nerezové podlahové krabice PUK',
    value: 'Nerezové podlahové krabice PUK',
  },
  {
    label: 'Venkovní podlahové systémy MOSER',
    value: 'Venkovní podlahové systémy MOSER',
  },
  {
    label: 'Stavební, přenosné a závěsné rozvaděče PCE',
    value: 'Stavební, přenosné a závěsné rozvaděče PCE',
  },
  {
    label: 'Zásuvkové skříně Schmachtl',
    value: 'Zásuvkové skříně Schmachtl',
  },
  { label: 'Vypínače a zásuvky VÍMAR', value: 'Vypínače a zásuvky VÍMAR' },
  {
    label: 'Přípojnicové systémy GRAZIADIO ',
    value: 'Přípojnicové systémy GRAZIADIO ',
  },
  { label: 'Plochý kabel Wieland', value: 'Plochý kabel Wieland' },
  {
    label: 'Elektroinstalační krabice Kaiser',
    value: 'Elektroinstalační krabice Kaiser',
  },
  {
    label: 'Kotvící prvky Schmabl a Erico-Caddy',
    value: 'Kotvící prvky Schmabl a Erico-Caddy',
  },
  { label: 'Záložní zdroje Riello', value: 'Záložní zdroje Riello' },
  {
    label: 'Fotovoltaické systémy BatterX',
    value: 'Fotovoltaické systémy BatterX',
  },
];
