import React from 'react';
import Heading from '../components/Heading';
import AddPost from '../components/AddPost';

const AddProject = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Přídat projekt'} />
      <AddPost />
    </div>
  );
};

export default AddProject;
