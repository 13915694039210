import React from 'react'
import PasswordForgot from './auth/PasswordForgot'
import Projektant from '../assets/img/projektant.png';

import {Link} from 'react-router-dom'

const ForgotPassword = () => {
    return (
        <div className="bg-gradient-primary h-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-12 col-xl-10">
              <div className="card shadow-lg o-hidden border-0 my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-flex justify-content-center">
                      <img src={Projektant} alt={'Projektant'} />
                    </div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h4 className="text-dark mb-4">Zapomenuté heslo</h4>
                        </div>
                        <PasswordForgot />
  
                  
                        <div className="text-center">
                         <Link to='/'>
                            Přihlásit se
                            </Link>
                        </div>
                        <div className="text-center">
                        <Link to='/registrace'>
                            Vytvořit účet
                            </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ForgotPassword
