import { gql } from '@apollo/client';

export const PROFILE = gql`
  query {
    profile {
      _id
      username
      email
      image {
        url
        public_id
      }
      credit
      firma
      jmeno
      prijmeni
      ulice
      mesto
      psc
      telefon
      obchodak
      createdAt
      updatedAt
      rewards {
        gift
        date
        cost
      }
    }
  }
`;

export const PROFILE_IMAGE = gql`
  query {
    profile {
      image {
        url
        public_id
      }
      jmeno
      prijmeni
    }
  }
`;

export const ALL_USERS_LIST = gql`
  query {
    allUsers {
      jmeno
      prijmeni
      firma
      obchodak
      createdAt
      email
    }
  }
`;

export const USER_ALL_REWARDS_LIST = gql`
  query {
    profile {
      rewards {
        gift
        date
        cost
      }
    }
  }
`;

export const ALL_USERS_All_REWARDS_LIST = gql`
  query {
    allRewards {
      _id
      jmeno
      prijmeni
      email
      firma
      rewards {
        gift
        cost
      }
    }
  }
`;

export const IS_ADMIN = gql`
  query {
    isAdmin {
      level
    }
  }
`;
