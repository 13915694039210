import React from 'react';
import ProjectUpdate from '../components/ProjectUpdate';
import Heading from '../components/Heading';

const UpdateProject = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Upravit projekt'} />
      <ProjectUpdate />;
    </div>
  );
};

export default UpdateProject;
