import React from 'react';
import Heading from '../components/Heading';
import ProjectDetails from '../components/ProjectDetails';

const Project = () => {
  return (
    <div className="container-fluid">
      <Heading title={'Detaily projektu'} />
      <ProjectDetails />
    </div>
  );
};

export default Project;
