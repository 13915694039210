/*import React, { useState } from 'react';
import FileBase64 from 'react-file-base64';

const File = () => {
  const [files, setFiles] = useState({
    soubor: [],
  });

  const { soubor } = files;

  const getFiles = (files) => {
    setFiles({ soubor: files });
  };

  console.log(soubor);

  return <FileBase64 multiple={true} onDone={getFiles} />;
};

export default File;
*/

import React, { useContext, Fragment } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';
import Image from '../Image'



const FileUpload = ({
  setValues,
  setLoading,
  values,
  loading,
  singleUpload = false,
}) => {
  const { state } = useContext(AuthContext);

  const fileResizeAndUpload = (e) => {
    setLoading(true);
    let files = e.target.files;

    let fileInput = files[0];
    {
      let reader = new FileReader();
      reader.readAsDataURL(fileInput);

      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: fileInput.name,
          type: fileInput.type,
          size: Math.round(fileInput.size / 1000) + ' kB',
          base64: reader.result,
          file: fileInput,
        };

        console.log(fileInfo);

        axios
          .post(
            `${process.env.REACT_APP_REST_ENDPOIT}/uploadimages`,
            { image: fileInfo.base64 },
            {
              headers: {
                authtoken: state.user.token,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            console.log('Cloudinary upload', response);

            // setValues to parent component based on either its
            // used for single/multiple upload
            if (singleUpload) {
              // single upload
              const { image } = values;
              setValues({ ...values, image: response.data });
            } else {
              const { images } = values;
              setValues({ ...values, images: [...images, response.data] });
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log('Cloudinary upload failed', error);
          });
      };
    }
  };

  const handleImageRemove = (id) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_REST_ENDPOIT}/removeimage`,
        {
          public_id: id,
        },
        {
          headers: {
            authtoken: state.user.token,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        // setValues to parent component based on either its
        // used for single/multiple upload

        if (singleUpload) {
          const { image } = values;

          setValues({
            ...values,
            image: {
              url: '',
              public_id: '',
            },
          });
        } else {
          const { images } = values;
          let filteredImages = images.filter((item) => {
            return item.public_id !== id;
          });
          setValues({ ...values, images: filteredImages });
        }
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <div className="col-md-5">
        <div className="form-group">
          <label className="btn btn-primary">
            Nahrát dokument
            <input
              hidden
              type="file"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
              text/plain, application/pdf, image/* ,application/pdf,image/x-eps"
              onChange={fileResizeAndUpload}
              className="form-control"
              placeholder="Image"
              disabled={loading}
            />
          </label>
        </div>
      </div>

      <div className="col-md-7">

{/* for single image*/}



{values.images && values.images.map((image) => (
  <Image
    image={image}
    key={image.public_id}
    handleImageRemove={handleImageRemove}
  />
))}

</div>
    </div>
  );
};

export default FileUpload;

/*
/{values.images && values.images.map((image) => (
  <Image
    image={image}
    key={image.public_id}
    handleImageRemove={handleImageRemove}
  />
))}

*/