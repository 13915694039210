import React from 'react';
import Search from'../components/forms/Search'
import TopBarContent from '../components/TopBarContent'

const TopBar = () => {
  return (
    <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
      <div className="container-fluid">
        <Search />
        <TopBarContent />
      </div>
    </nav>
  );
};

export default TopBar;
