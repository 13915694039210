import React from 'react';

const Search = () => {
  return (
    <div>
      <button
        className="btn btn-link d-md-none rounded-circle mr-3"
        id="sidebarToggleTop"
        type="button"
      >
        <i className="fas fa-bars"></i>
      </button>
      <form className="form-inline d-none d-sm-inline-block mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input
            className="bg-light form-control border-0 small"
            type="text"
            placeholder="Vyhledat"
          />
          <div className="input-group-append">
            <button className="btn btn-primary py-0" type="button">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Search;
