import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import { POST_CREATE, POST_DELETE } from '../../graphql/mutations/post/post';
import { POSTS_BY_USER } from '../../graphql/queries/post/Post';
import { CREDIT } from '../../graphql/queries/credit/Credit';
import FileUpload from '../../components/forms/FileUpload';
import MultiSelect from 'react-multi-select-component';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';
import {
  druhOptions,
  stupenOptions,
  skupinaOptions,
} from '../../data/FormsOptions';

registerLocale('cs', cs);

const initialState = {
  image: {
    url: 'https://via.placeholder.com/200x200.png?text=Projekt',
    public_id: '123',
  },
  images: [],
  projekt: '',
  stupen: 'Investiční záměr',
  druh: 'Administrativa',
  misto: '',
  investor: '',
  skupina: [],
  mnozstvi: '',
};

const AddPostForm = () => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  //query

  // descructure

  const {
    images,
    image,
    projekt,
    stupen,
    druh,
    misto,
    investor,
    skupina,
    mnozstvi,
  } = values;

  console.log(values);

  // mutation

  const [postCreate] = useMutation(POST_CREATE, {
    refetchQueries: [
      {
        query: CREDIT,
      },
    ],
    // update cache
    update: (cache, { data: { postCreate } }) => {
      //readQuery from cache
      const { postsByUser } = cache.readQuery({ query: POSTS_BY_USER });
      // write Query to cache

      cache.writeQuery({
        query: POSTS_BY_USER,
        data: {
          postsByUser: [postCreate, ...postsByUser],
        },
      });
    },

    onError: (err) => console.log(err),
  });

  const [postDelete] = useMutation(POST_DELETE, {
    update: ({ data }) => {
      console.log('POST DELETE MUTATION,', data);
      toast.error('Projekt odebrán');
    },
    onError: (err) => {
      console.log(err);

      toast.error('Chyba');
    },
  });

  const handleDelete = async (postId) => {
    let answer = window.confirm('Vymazat?');
    if (answer) {
      setLoading(true);
      postDelete({
        variables: { postId },
        refetchQueries: [{ query: POSTS_BY_USER }, { query: CREDIT }],
      });
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payout = { ...values, skupina: selected, date: startDate };
    console.log(payout);

    postCreate({ variables: { input: payout } });
    setValues(initialState);
    setLoading(false);
    toast.success('Projekt přídán');
  };
  const handleChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      //   skupina: [...selected],
      [e.target.name]: e.target.value,
    });
  };

  const createForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <p className="text-primary font-weight-bold m-0">
            Informace o projektu
          </p>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-lg-6">
              <div className="col">
                {' '}
                <div className="form-group">
                  <label>
                    <strong>Název projektu</strong>
                  </label>
                  <input
                    type="text"
                    name="projekt"
                    value={projekt}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>

              <div className="col">
                {' '}
                <div className="form-group">
                  <label>
                    <strong>Stupeň projektové dokumentace</strong>{' '}
                  </label>
                  <br></br>
                  <select
                    value={stupen}
                    className="form-control"
                    name="stupen"
                    disabled={loading}
                    onChange={handleChange}
                    required="true"
                  >
                    {stupenOptions.map((stupen) => (
                      <option value={stupen.value}>{stupen.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Druh stavby</strong>
                  </label>
                  <br></br>
                  <select
                    value={druh}
                    className="form-control"
                    name="druh"
                    disabled={loading}
                    onChange={handleChange}
                    required="true"
                  >
                    {druhOptions.map((druh) => (
                      <option value={druh.value}>{druh.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Místo stavby</strong>
                  </label>
                  <input
                    type="text"
                    name="misto"
                    value={misto}
                    onChange={handleChange}
                    required={true}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group pt-4">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    Vytvořit projekt
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Investor</strong>
                  </label>
                  <input
                    type="text"
                    name="investor"
                    value={investor}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Skupina zboží</strong>
                  </label>

                  <MultiSelect
                    options={skupinaOptions}
                    value={selected}
                    onChange={setSelected}
                    labelledBy={'Select'}
                    overrideStrings={{
                      selectSomeItems: 'Vyberte skupinu zboží...',
                      allItemsAreSelected: 'Vybral jste všechny skupiny.',
                      selectAll: 'Vybrat vše',
                      search: 'Vyhledat',
                      clearSearch: 'Hledání',
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Množství přibližné</strong>
                  </label>
                  <input
                    type="text"
                    name="mnozstvi"
                    value={mnozstvi}
                    onChange={handleChange}
                    className="form-control"
                    placeholder=""
                    disabled={loading}
                    required="true"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>
                    <strong>Předpokládaný termím realizace </strong>
                  </label>
                  <div>     <DatePicker
                    locale="cs"
                    minDate={new Date()}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  /></div>
            
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div className="row">
      <div className="col-lg-8">
        {loading ? <h4>Nahrávám</h4> : ''} {createForm()}{' '}
      </div>
      <div className="col-lg-4">
        {' '}
        <div className="card shadow mb-4">
          {' '}
          <div className="card-header py-3">
            <h6 className="text-primary font-weight-bold m-0">
              Přiložit soubory{' '}
            </h6>
          </div>
          <div className="card-body">
            Nahrané soubory {images.length}
            <div className="form-group pt-4">
              <FileUpload
                setValues={setValues}
                setLoading={setLoading}
                values={values}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPostForm;
